.navbar {
    margin: auto;
    max-width: 1440px;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .navbarLogoWrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 768px) {
    .navbar {
        position: sticky;
        top: 0;
        background-color: white;
        z-index: 50;
    }
}
.link {
    position: relative;
    text-decoration: none;
    margin: 0 30px;
    color: black;
    &:hover {
        color: hsl(0, 94%, 66%);
    }
    &::after {
        content: '';
        position: absolute;
        width: 100%;
        transform: scaleX(0);
        height: 3px;
        bottom: -5px;
        left: 0;
        background-color: hsl(0, 94%, 66%);
        transform-origin: bottom right;
        transition: transform 0.25s ease-out;
    }

    &:hover::after {
        transform: scaleX(1);
        transform-origin: bottom left;
    }
}

.navItemactive {
    color: hsl(0, 94%, 66%);
}

.login {
    text-decoration: none;
    color: black;
    padding: 10px 30px;
    border: 3px solid transparent;
    border-radius: 6px;
    transition:
        border 200ms ease-in-out,
        color 200ms ease-in-out;

    &:hover {
        border: 3px solid hsl(0, 94%, 66%);
        color: hsl(0, 94%, 66%);
    }
}

.hamburgerMenuTrigger {
    height: 30px;
    width: auto;
}

.navbarMenu {
    position: fixed;
    z-index: 2;
    right: -100vw;
    top: 0;
    background-color: hsl(229, 31%, 21%);
    opacity: 0.95;
    height: 100vh;
    width: 100vw;
    transition: right 450ms ease-in-out;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .hamburgerMenuTrigger {
        position: absolute;
        right: 30px;
        top: 30px;
    }

    .link,
    .login {
        margin-bottom: 40px;
        font-size: 20px;
        color: white;
        border-top: 2px solid white;
        width: 60%;
        text-align: center;
        padding: 20px;

        &::after {
            background-color: white;
        }
    }

    .navItemactive {
        color: hsl(0, 94%, 66%);
    }

    .navbarMenuLogo {
        position: absolute;
        left: 30px;
        top: 30px;
        width: 40%;
        font-weight: 500;
    }
}

.navbarMenuActive {
    right: 0;
}

.jiggle {
    animation: jiggle 0.2s infinite;
    -webkit-animation: jiggle 0.2s infinite;
    -moz-animation-duration: 0.2s;
    -moz-animation-name: jiggle;
    -moz-animation-iteration-count: infinite;
    -webkit-transform: rotate(-1deg);
    -moz-transform: rotate(-1deg);
}

@keyframes jiggle {
    0% {
        transform: rotate(-1deg);
    }
    50% {
        transform: rotate(10deg);
    }
}

@-webkit-keyframes jiggle {
    0% {
        -webkit-transform: rotate(-1deg);
    }
    50% {
        -webkit-transform: rotate(10deg);
    }
}

@-moz-keyframes jiggle {
    0% {
        -moz-transform: rotate(-1deg);
    }
    50% {
        -moz-transform: rotate(10deg);
    }
}
