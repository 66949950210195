$blue: hsl(231, 69%, 60%);
$blueActive: hsl(231, 69%, 55%);
$red: hsl(0, 94%, 66%);
$redActive: hsl(0, 94%, 61%);
$default: hsl(229, 8%, 60%);
$defaultActive: hsl(229, 8%, 55%);
$defaultDisabled: hsl(0, 0%, 38%);

body {
    font-family: Rubik;
}

.btn {
    all: unset;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    padding: 8px 12px;
    border-radius: 4px;
    font-size: 0.7em;
    cursor: pointer;
    font-weight: 500;

    background-color: $default;
    color: black;

    &:hover {
        background-color: $defaultActive;
        box-shadow: 0 0 8px 0 $defaultActive;
    }

    &.btn--blue {
        background-color: $blue;
        color: white;

        &:hover {
            background-color: $blueActive;
            box-shadow: 0 0 8px 0 $blueActive;
        }
    }

    &.btn--red {
        background-color: $red;
        color: white;

        &:hover {
            background-color: $redActive;
            box-shadow: 0 0 8px 0 $redActive;
        }
    }

    &.btn--disabled {
        background-color: $defaultDisabled;
        color: white;
        cursor: default;

        &:hover {
            background-color: $defaultDisabled;
            box-shadow: none;
        }
    }
}
